<template>
  <div class="auth__form__content" id="auth-login">
    <form v-on:submit="submit">
      <h2 class="is-size-2">Forgot Password</h2>
      <div class="is-size-4">
        Please enter your email address. An email will be send to you shortly to
        reset your password
      </div>
      <br />
      <b-field label="Email">
        <b-input
          placeholder="e.g: admin@roketin.com"
          v-model="email"
          required
          size="is-size-5"
        ></b-input>
      </b-field>
      <b-field class="is-flex is-justify-content-center">
        <b-button @click="submit" class="is-size-5 mt-2 mb-2" expanded>
          Submit
        </b-button>
      </b-field>
    </form>
    <div class="auth__form__footer">
      <div class="is-size-5">
        {{ `Remember your password? ` }}
        <span>
          <router-link
            to="login"
            class="is-link is-size-5 has-text-weight-semi-bold"
          >
            Login
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    submit(e) {
      e.preventDefault()
      let email = this.email
      this.$store
        .dispatch('auth/forgotPassword', { email })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: `Email Has Been Sent!`,
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
